.page-base {
	&__container {
		@extend .container;
		padding: 20px 0 20px;

		@include mq($screen-md) {
			padding: 50px 0 50px;
		}

		a:not(.button) {
			&:active,
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	&__row {
		@extend .row;
	}

	&__col {
		&--1 {
			@extend .col-12;
		}
		&--2 {
			@extend .col-12, .col-md-6;
		}
		&--3 {
			@extend .col-12, .col-md-3;
		}
	}
}


.page-header__container {
  width: 100%;
  padding: 15px;

  @include mq($screen-md) {
    max-width: $screen-xl;
    margin: 0 auto;
  }
}

.page-header__breadcrumb {
	background-color: transparent;
	margin: 0 auto;
	padding: 0;

  @include media-breakpoint-up(md) {
    margin: 0;
  }

	.breadcrumb-item {
    float: none;

    form {
      display: inline-block;
    }

    @include media-breakpoint-up(md) {
      display: inline-block;
    }

    .badge-master {
      font-size: $font-size-xs;
      margin-left: 3px;
      padding: 2px 6px;
      line-height: 1;
    }

		&,
		&.active {
			color: $cnc-tan;
		}

    a,
    button {
      display: inline-block;
      color: $cnc-tan;
      background: none;
      border: 0;
      padding: 0;
      max-width: 11rem;
      text-overflow: ellipsis;
      line-height: 1;
      max-height: 1rem;
      white-space: nowrap;
      overflow: hidden;

      @include hover-states {
        outline: 0;
        text-decoration: underline;
        cursor: pointer;
        max-width: 100%;
      }
    }

		+ .breadcrumb-item {
			&::before {
				font-family: 'fontAwesome';
				content: "\f101";
			}
		}

    &:last-child {
      &::after {
        font-family: 'fontAwesome';
        content: "\f101";
        padding-left: .25rem;
      }
    }
	}
}

.page-header {
  text-align: center;
  border-bottom: 1px solid $border-color;
  padding: 1rem;

  @include media-breakpoint-up(lg) {
    display: flex;
    text-align: left;
  }

  &__avatar,
  &__info {
    display: block;
    text-align: center;
    width: 100%;
    margin: auto;

    @include media-breakpoint-up(lg) {
      display: inline-flex;
      flex-direction: column;
      flex: 1;
      align-items: flex-start;
      align-content: center;
      justify-content: center;
      height: 100%;
      vertical-align: middle;
      text-align: left;
    }
  }

  &__avatar {
    max-width: 70%;
    padding: 1rem;

    @include media-breakpoint-up(sm) {
      max-width: 40%;
    }

    @include media-breakpoint-up(md) {
      max-width: 30%;
    }

    @include media-breakpoint-up(lg) {
      max-width: 140px;
      margin-right: .5rem;
    }

    img {
      height: auto;
      max-width: 100%;
      max-height: 100px;
    }
  }

  &__last-login {
  	display: inline-block;
  	font-size: $font-size-sm;
  	font-weight: 300;
  	color: $gray-light;
  }

  &__info {
    h2 {
    	display: inline-flex;
    	align-items: center;
      margin-bottom: 0;

      i {
      	color: $cnc-tan;
      }
    }

    > a {
      color: $cnc-blue;

      @include hover-states {
        text-decoration: none;
      }
    }
  }

  &__actions {
    margin-top: .5rem;
  }
}
