@mixin mq( $screensize ) {

	@media only screen and (min-width : $screensize) { @content; }

}

@mixin mq-down( $screensize ) {

	@media only screen and (max-width : $screensize) { @content; }

}