/*  Reusable Students Style
	------------------------------------ */

.student__section {
	@extend .row, .no-gutters;
	margin-top: 2rem;

	&-heading {
		margin-bottom: 1.5rem;
		text-transform: uppercase;
		color: $cnc-tan;
		border-bottom: 1px solid $border-color;
		padding-bottom: .5rem;
	}
}

.student__card {
	@extend .card;
	width: 100%;

	&.no-border-radius {
		border-radius: 0;
	}

	&-header {

		@extend .card-header;

		@include mq($screen-sm) {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&.subhead {
			background: $card-subcap-bg;

			.button {
				min-width: 3.5rem;
				max-width: 3.5rem;
				padding: .5rem .65rem;

				i {
					display: block;
				}
			}
		}

		&-actions {
			@extend .row, .no-gutters;
			margin-top: .5rem;

			@include mq($screen-sm) {
				margin-top: 0;
			}

			.button {
				@extend .col;
				min-width: 8rem;
				max-width: 8rem;
			}

			.button-link {
				order: 2;

				@include mq($screen-sm) {
					order: 1;
					margin-right: .65rem;
				}
			}

			.button-primary {
				order: 1;

				@include mq($screen-sm) {
					order: 2;
				}
			}
		}
	}

	&-title {
		display: block;
		text-transform: uppercase;
		margin: 0;

		@include mq($screen-sm) {
			display: flex;
		}
	}
}

.student {
	&__table {
		@extend .table, .table-sm;
		margin-top: 1.5rem;

		th {
			border-top: 0;
		}

		@include mq($screen-md) {
		  display: table;
		}

		th {
			white-space: nowrap;
			text-transform: uppercase;
			border-bottom: 1px solid $border-color;
			color: $gray-light;
		}

		th,
		td {
			vertical-align: middle;
			&:nth-child(n+2) {
				text-align: center;
			}
		}

	}

	&__cell {

		&-actions {
			min-width: 8rem;
		}
	}

}
