$calendar-box-shadow: 0 3px 4px -2px rgba(0, 0, 0, .45);

.calendar {
  @include media-breakpoint-up(md) {
    min-height: 260px;
  }

  @include media-breakpoint-up(lg) {
    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  display: inline-block;
  vertical-align: top;
  border-collapse: collapse;
  border-spacing: 2px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  box-shadow: 0 3px 4px -2px rgba(0, 0, 0, .45);
  min-height: 275px;

  th,
  td {
    border: 1px solid $white;
  }

  th {
    font-weight: normal;
    font-size: $font-size-xs;
    padding: 2px;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.8rem;

    &.month {
      font-weight: bold;
      font-size: $font-size-base;
      background-color: $cnc-blue-lightest;
      border: 0;
    }
  }

  td {
    text-align: center;
    position: relative;
  }

  .calendar__day {
    @include media-breakpoint-up(lg) {
      width: 2.15rem;
      height: 1.85rem;
    }

    font-size: $font-size-sm;
    width: 2.5rem;
    height: 2rem;

    &.sat,
    &.sun,
    &.excluded {
      color: $gray-lighter;
    }
  }

  .holiday {
    position: relative;
    background-color: rgba($cnc-yellow, .35);
  }
}
