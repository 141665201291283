html,
body {
	position: relative;
	min-height: 100%;
}

main {
	//margin-top: 72px;
	min-height: 1000px;
	//min-height: 100vh;
	padding-bottom: 200px;
}