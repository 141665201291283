/*  Custom cards

    I mostly only made changed to the cnc-block header. So I'm just being lazy and just do the header part. Obviously the parent already has `cnc-card`, so overwriting `card-block` is trivial without the same naming convention.

    Example:

    <div class="cnc-card">
      <div class="cnc-card__header">
        <h4 class="cnc-card__title">{{ title }}</h4>
      </div>
      <div class="cnc-card__header-actions">
        <div class="cnc-card__header-col">
          <button type="submit" class="button button-accent button--sm">Button</button>
          <button type="submit" class="button button-primary button--sm">Button</button>
        </div>
      </div>

      <div class="cnc-card__block">
        ...
      </div>

      <div class="cnc-card__footer">...</div>
    </div>
  ------------------------------------ */

.cnc-card {
  @extend .card;
  width: 100%;

  &__header {

    @extend .card-header;
    background-color: $cnc-blue-lightest;

    @include mq($screen-sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .fa {
      display: inline-block;
      margin-right: .5rem;
    }

    &.subhead {
      background: $card-subcap-bg;

      .button {
        min-width: 3.5rem;
        max-width: 3.5rem;
        padding: .5rem .65rem;

        i {
          display: block;
        }
      }
    }

    &-col {
       @extend .col;
       //Make sure to add a min/max-width to the column individually. Otherwise, IE11 won't work.

       padding-left: 0;
    }

    &-actions {
      @extend .row, .no-gutters;
      margin-top: .5rem;

      @include mq($screen-sm) {
        margin-top: 0;
      }

      .dropdown-menu {
        left: -3.8rem; //Pushes off too much to the right;
      }

      .button {
        @extend .col;
        min-width: 8rem;
        max-width: 8rem;
      }

      .button-link {
        order: 2;

        @include mq($screen-sm) {
          order: 1;
        }
      }

      .button-primary {
        order: 1;

        @include mq($screen-sm) {
          order: 2;
        }
      }
    }
  }

  &__title {
    display: block;
    text-transform: uppercase;
    margin: 0;
    line-height: 1.3;
    color: $cnc-tan;

    small {
      text-transform: none;
      display: block;
      width: 100%;
      color: $gray;
    }
  }

  &__block {
    @extend .card-block;
  }

  &__footer {
    @extend .card-footer;

    text-align: center;
    padding-bottom: 1rem;

    a {
      text-decoration: underline;
    }

    .btn, .button {
      text-decoration: none;
    }
  }
}
