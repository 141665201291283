@mixin hover-states ($activeClass: false, $trans: true) {
	&:hover,
	&:focus,
	&:active {
		@content;

		@if $trans {
			transition: $transition-base;
		}
	}

	@if $activeClass {
		&.is-active,
		&.active {
			@content;
		}
	}
}