@media print {
		* {
		-webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
		color-adjust: exact !important;                 /*Firefox*/
	}
	.page-break	{
		display: block !important;;
		page-break-before: always !important;;
		position: relative !important;;
	}

	.print-container {
    page-break-inside: avoid !important;
    display: block !important;
		width: 100% !important;
		position: relative !important;;
  }

  .legal-container {
    width: auto;
  }

	//Stupid BS4's text-shadow/box-shadow pushes the first letter down
	//https://github.com/twbs/bootstrap/issues/21771
	::first-letter{
	  margin: 0;
	  padding: 0;
	}

	header.nav-main__header {
    height: auto;
	}

	#utilities-container,
  #freshworks-frame,
  #freshworks-container,
  .nav-main__search,
  .nav-main__right {
		display: none !important;
	}

	main header,
	.details__nav,
	.student-detail__info-avatar,
	#student-report,
	#view-report,
	.subhead .form-group,
	.program-report__add-data,
	.program-report__toplink,
	footer {
		display: none;
	}

	body {
		font: 12pt Arial, sans-serif;
	}

	main {
		.student-detail {
			min-height: auto;
		}

		.student-detail__name {
			font-size: 1.6rem !important;
			font-weight: bold;
			margin-bottom: 0;
		}

		.student-detail__info-gpa {
			svg {
				display: none;
			}
		}

		.student-detail__info-gpa-heading {
			font-size: 1.2rem;
		}

		.program-report .cnc-card__header.subhead {
			margin-bottom: 0;
		}

		.heatmap {
			height: 240px;
		}

		.card-block {
			padding-bottom: 0;
		}
	}
}
