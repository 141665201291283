.dropdown {
  transition: all 0.3s ease;

  a,
  button {
    &:after {
      transition: all 0.25s ease;
    }
  }

  &-toggle {
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      outline: 0;
      cursor: pointer;
    }
  }

  &-menu {
    //display: block;
    // visibility: hidden;
    // opacity: 0;
    // top: 60%;
    border-radius: 0;
    transition: all 0.2s  ease;
  }

  &.show {
    .dropdown-menu {
      // visibility: visible;
      // opacity: 1;
      // top: 100%;
    }

    a,
    button {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }

  &-item {
    font-size: $font-size-sm;
    padding: .35rem 1.5rem;
  }
}