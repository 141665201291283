/*  Toggleable Section

	Usage:

	<button type="button" class="button button-primary" data-js="toggle-section-control" data-section="section-id">
	  <i class="fa fa-plus-circle"></i>
	</button>

	<div class="whatever-you-want" data-js="toggle-section" data-section-id="section-id">
		...
	</div>

	NOTE: Make sure to add a fixed height for the `.whatever-you-want` div so it can have a nice and smooth animation. If you don't. It'll still work. But it just "jumps" out instead of sliding up and down.
	------------------------------------ */

[data-js="toggle-section-control"] {
	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
	}
}

[data-js="toggle-section"] {
	opacity: 0;
	height: 0px;
	overflow: hidden;
	margin-top: -1px;
	transition: height 0.5s ease-in-out, opacity 0.3s ease 0.5s;

	&.is-opened {
		opacity: 1;
		height: auto;
		overflow: visible;
	}
}