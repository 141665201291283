.table {
	@extend .table, .table-responsive;

	@include mq($screen-md) {
		display: table;
	}

	thead {
		th {
			padding: .5rem;
		}
	}
	
	td {
		line-height: 1.25;
	}
}