.account {
	@extend .col-8, .col-md-6;

	&__org {
		@extend .col-11, .col-md-10;
	}

	&__row {
		@extend .align-content-center, .justify-content-center;
	}

	&-heading {
		margin-bottom: 2rem;
	}

	&-login,
	&-logout {
		@extend .col-8, .col-md-5;

		&__row {
			padding-top: 5rem;
		}


		h2 {
			margin-bottom: 0;
			text-transform: uppercase;
			color: $gray;
		}

		p {
			font-size: $font-size-base;
			font-weight: 300;
		}

    .cnc-card__footer {
      .card-link {
        ~ p {
          margin: 0;
        }
        + p {
          margin-top: 1rem;
        }
      }
    }
	}

	.card-footer {
		text-align: center;
		padding-bottom: 1rem;
	}

	&__thankyou {
		@extend .col-11, .col-sm-12, .col-md-11, .align-content-center;

		&-jumbotron {
			@extend .jumbotron;
			display: block;
			width: 100%;
			border-radius: 0;
			background-color: $cnc-yellow;

		}

		.media {
			@extend .row;
			align-items: center;

			&-body {
				@extend .col-12, .col-sm-8;
				text-align: center;

				@include mq($screen-md) {
					text-align: left;
				}

				h2 {
					font-size: 2.5rem;
					font-weight: 300;
					line-height: 1.2;
				}
			}
		}

		.cnc-logo {
			@extend .col-12, .col-sm-3;
			max-width: 200px;
			margin: 0 auto 1rem;

			@include mq($screen-sm) {
				max-width: 150px;
			}

			@include mq($screen-md) {
				max-width: 180px;
				margin-bottom: 0;
			}
		}

		&-img {
			width: 100%;
		}
	}

}
