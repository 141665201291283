svg {
  background-color: transparent !important;
  overflow-x: scroll;

  @include mq($screen-md) {
    overflow-x: visible;
  }


  .title,
  text {
    font-family: $font-family-base !important;
  }

  .title {
    font-size: $font-size-lg !important;
    font-weight: 500;
    text-transform: capitalize;
    fill: $gray !important;
  }

  .legends .legend text {
    font-size: $font-size-xs !important;
  }

  .graph > .background {
    fill: none !important;
  }

  .guide.line {
    stroke-dasharray: none !important;
    stroke: rgba($black, 0.10) !important;
  }
}