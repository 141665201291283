.page-landing {
	&__container {
		@extend .container-fluid;
		margin-bottom: -20px;
	}

	&__header {
		background: $cnc-blue-lightest;
	    width: 100%;
	    text-align: center;
	    padding: 15px 0;

	    h2 {
	    	color: $cnc-blue;
	    	margin: 0;
	    }
	}

	&__body {
		@extend .align-items-center, .justify-content-center;

		background-color: $cnc-orange;
		color: $gray-dark;

		&--left,
		&--center,
		&--right {
			padding: 20px;
			text-align: center;

			@include mq($screen-lg) {
				padding: 40px;
			}

			@include mq($screen-lg) {
				text-align: left;
			}
		}

		&--left {
			@extend .offset-md-1, .col-md-3;

			img {
				padding: 0 30px;
				max-width: 70%;

				@include mq($screen-sm) {
					max-width: 50%;
				}

				@include mq($screen-md) {
					max-width: 90%;
				}

				@include mq($screen-lg) {
					max-width: 100%;
				}
			}
		}

		&--center {
			@extend .col-md-4;

			font-size: $font-size-xlg;
			line-height: 1.8;
		}

		&--right {
			@extend .col-md-3, .align-self-end;
			padding-top: 0;

			@include mq($screen-sm) {
				margin-top: 2rem;
			}
		}
	}

	&__footer {
		text-align: center;

		&--bg {
			@extend .col;
			background-position-y: 35%;
		}

		&-cta {
			@extend .align-content-center, .justify-content-center;
			@extend .align-items-md-center, .justify-content-md-center;
			height: 422px;


			&--left,
			&--right {
				@extend .col-12, .col-md-6;
				margin-bottom: 1rem;

				.button {
					font-size: $font-size-sm;

					@include mq($screen-sm) {
						font-size: $font-size-lg;
					}
				}
			}
		}
	}
}