.badge {
	line-height: 1rem;

	&-master {
		font-size: $font-size-sm;
		font-weight: 300;
		margin-left: .5rem;
		background-color: $cnc-tan;
	}

	&-circle {
		border-radius: 50%;
	}

	&-is-school {
		background-color: $cnc-yellow;
		color: $gray-light;
		width: 1.25rem;
		height: 1.25rem;
		line-height: 0.9rem;
		font-size: 0.8rem;
		margin-left: .25rem;
	}
}

.cnc-badge {
	display: inline-block;
	background-color: $white;
	color: $gray-lighter;
	border: 1px solid $gray-lighter;
	padding: 1rem 1.5rem;
	border-radius: $border-radius;
	text-align: center;

	&--primary {
		background-color: $cnc-blue-light;
		color: $white;
	}

	&__title {
		display: block;
		font-family: inherit;
		font-size: $font-size-base;

		&.small {
			font-size: $font-size-sm;
		}
	}

	&__heading {
		display: block;
		font-size: 3rem;
	}

	&__subheading {
		display: block;
		font-size: $font-size-base;
	}
}

.badge-info {
	background-color: #62bcfc;
	color: $white;
	font-size: 12px;
	padding: 2px 8px;
	font-weight: 400;
}