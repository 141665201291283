.student-detail__info-gpa {
	font-family: $font-family-sans-serif !important;
	font-size: 100% !important;
	fill: transparent !important;

	svg {
		background-color: transparent !important;
		min-width: 230px;
	}

	.background {
		fill: transparent !important;
	}

	.gauge-background {
		fill: $graph-gauge-bg !important;
		fill-opacity: 1 !important;
		stroke-opacity: 1 !important;
	}

	.color-0 {
		fill: $graph-gauge-color !important;
		stroke: $graph-gauge-color !important;
		fill-opacity: 1 !important;
	}

	.gauge-sum {
		font-family: $font-family-sans-serif !important;
		font-size: 8rem !important;
		font-weight: bold;
		fill: $gray !important;
	}

	text.value.gauge-sum {
	    transform: translate(0px, 40px);
	}

	.tooltip {
		.value {
			font-family: $font-family-sans-serif !important;
			font-size: 5rem !important;
			fill: $gray !important;
		}
	}

	.legend {
		font-size: 3rem !important;
		text-align: center;
	}

}