.org-admin-form {
  @extend .row;

  .org-checkbox {
    .checkbox {
      padding-top: 0 !important;
    }
  }

  &-buttons {
    margin: 1rem 0 1.5rem;
    justify-content: center;
  }

  &-button-save,
  &-button-cancel {
    @extend .col-6, .col-md-4;

    .button {
      width: 100%
    }
  }

  .bootstrap4-multi-input {
    margin: 0;
    padding: 0;

    > * {
      display: inline-block;
      vertical-align: middle;
      line-height: 1.5rem;
    }

    a {
      display: inline-block;
      background-color: $gray-lighter;
      padding: .25rem;
      overflow: scroll;
      max-width: 13rem;
      line-height: 1.8rem;
      vertical-align: middle;
      margin-right: .5rem;

      @include mq($screen-sm) {
        display: block;
      }

       @include mq($screen-md) {
        max-width: 12rem;
        display: inline-block;
      }

      @include mq($screen-xl) {
        max-width: 20rem;
      }
    }

    label[for="avatar-clear_id"] {
      &:after {
        content: "";
        display: block;
        width: 1rem;
        height: 1rem;
      }
    }
  }

  &-credits {
    @extend .row, .align-items-center;
    margin: 0;
    padding: 0;


    .form-group {
      @extend .col-12, .col-sm-4;

      @include mq($screen-md) {
        padding: 0 1rem 0 0;
      }

      &.no-label,
      .checkbox {
        @include mq($screen-sm) {
          padding-top: 2rem;
        }
      }
    }
  }
}

/*  Org admin - Edit
  ------------------------------------ */

.org-admin-licenses {
  display: block;
  font-size: $font-size-sm;
  font-weight: 300;
  text-transform: uppercase;
  color: $gray;
  margin-bottom: 0;
}

.dashboard-admin__card fieldset {
  padding: 15px;
  background-color: ghostwhite;

  .fieldset-body {
    padding: 0 15px;
  }
}

/*  TODO: Move these to Dashboard and move _dashboard.scss into the page level
  ------------------------------------ */
.admin__backlink {
  width: 100%;
  padding: 0 15px 1rem;

  @include mq($screen-md) {
    max-width: $screen-xl;
    margin: 0 auto;
  }
}

.admin-form {
  @extend .row;

  .org-checkbox {
    .checkbox {
      padding-top: 0 !important;
    }
  }

  &__buttons {
    margin: 1rem 0 1.5rem;
    justify-content: center;
  }

  &__button-save,
  &__button-cancel {
    @extend .col-6, .col-md-4;

    .button {
      width: 100%
    }
  }

  &__graphs {
    @extend .form-inline;

    .form-group {
      margin-right: 1rem;
      margin-bottom: .75rem;

      &:last-of-type {
        label.form-control-label {
          white-space: nowrap;
        }
      }

      .checkbox {
        width: 5rem;

        label {
          width: 8rem;
        }

        @include mq($screen-sm) {
          padding-top: 0;
          width: 12rem;
        }

        @include mq($screen-md) {
          width: 9rem;
        }

        @include mq($screen-lg) {
          width: 8rem;
        }
      }

      label,
      .checkbox label input {
        margin-right: .5rem;
      }

      label {
        display: inline-block;
        width: 5rem;
        text-align: left
      }

      select,
      input[type="number"],
      input[type="text"] {
        border-radius: 0;
        min-width: 25rem;
        max-width: 25rem;

        @include mq($screen-sm) {
          min-width: 22rem;
          max-width: 22rem;
        }

        @include mq($screen-md) {
          min-width: 13.5rem;
          max-width: 13.5rem;
        }
      }
    }

    .cnc-form__button {
      margin-top: 1.25rem;
      @include mq($screen-sm) {
        margin-top: -12px;
      }
    }
  }

  .bootstrap4-multi-input {
    margin: 0;
    padding: 0;

    > * {
      display: inline-block;
      vertical-align: middle;
      line-height: 1.5rem;
    }

    a {
      display: inline-block;
      background-color: $gray-lighter;
      padding: .25rem;
      overflow: scroll;
      max-width: 13rem;
      line-height: 1.8rem;
      vertical-align: middle;
      margin-right: .5rem;

      @include mq($screen-sm) {
        display: block;
      }

       @include mq($screen-md) {
        max-width: 12rem;
        display: inline-block;
      }

      @include mq($screen-xl) {
        max-width: 20rem;
      }
    }

    label[for="avatar-clear_id"] {
      &:after {
        content: "";
        display: block;
        width: 1rem;
        height: 1rem;
      }
    }
  }

  &__credits {
    @extend .row, .align-items-center;
    margin: 0;
    padding: 0;


    .form-group {
      @extend .col-12, .col-sm-4;

      @include mq($screen-md) {
        padding: 0 1rem 0 0;
      }

      &.no-label,
      .checkbox {
        @include mq($screen-sm) {
          padding-top: 2rem;
        }
      }
    }
  }
}

.admin-charts {

  &__tabs {
    margin-top: 1rem;
  }
}

.admin-tab-content {
  overflow-x: visible;
  padding-top: 1.5rem;

  @include mq($screen-md) {
    padding: 1.5rem 2rem 0;
    margin-right: 1.5rem;
  }

  svg {
    min-height: 250px;
    @include mq($screen-md) {
      min-height: 400px;
    }
  }

  &--empty {
    display: flex;
    flex: 0 0 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    min-height: 300px;
    padding: 2rem;
    text-align: center;
  }
}

/*  Admin - Manage All Users
  ------------------------------------ */
.org-admin-users-col {
  width: 280px;
}

/* MANAGE COURESE UI */
.expired_course {
  &.is-opened {
    height: 100%;
  }
}
