//import autocomplete css for the search thingy

.nav-main__header {
  position: relative;
  //@extend .fixed-top;
  background: $cnc-yellow;
  padding:10px;
  height: 115px;
  box-shadow: 0px 3px 4px -2px rgba(0,0,0,0.45);
  z-index: 100;

  @include mq($screen-md) {
    height: 72px;
  }
}

.nav-main {
  @extend .row;
  flex-wrap: nowrap;
  width: 100%;
  max-width: $screen-xl;
  background-color: $cnc-yellow;
  margin: 0 auto;

  &__brand {
    @extend .navbar-brand;
    margin-right: .5rem;

    img {
      max-width: 160px;
      height: 2.8rem;
    }

    h1 {
      display: none;
    }
  }

  &__left {
    @extend .col, .col-md-8, .align-self-start, .no-gutters;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include mq($screen-md) {
      white-space: nowrap;
      flex-wrap: nowrap;
    }

    img {
      width: 160px;
    }

    h1 {
      display: none;
    }

    .version-identifier {
      display: inline-block;
      font-size: .5em;
      padding-bottom: 8px;
    }

    .tt-hint {
      font-size:12px;
    }
    .tt-dataset {
      width: 12rem;
      overflow-x: hidden;
      background: lighten($cnc-yellow, 10%);
      box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.45);

      @include mq($screen-md) {
        width: 18rem;
      }

      p {
        padding: .5rem;
        margin: 0;
        max-width: 12rem;
        text-overflow: ellipsis;
        overflow-x: hidden;

        @include mq($screen-md) {
          max-width: 18rem;
        }

        &:first-child {
          font-size: $font-size-sm;
          font-weight: bold;
          text-transform: uppercase;
          color: $gray;
          border-bottom: 1px dotted darken($cnc-yellow, 15%);
        }
      }

      .tt-suggestion {
        font-size: $font-size-sm;
        padding: .5rem;
        //background-color: darken($cnc-yellow, 10%);

        &:hover,
        &:focus,
        &:active {
          cursor: pointer;
          background-color: darken($cnc-yellow, 15%);
          text-decoration: underline;
        }
      }
    }
  }

  &__search {
    position: relative;
    margin-top: .25rem;

    @include mq($screen-md) {
      margin-top: 0;
      margin-left: 1.5rem;
    }

    input {
      font-size: $font-size-xs;
      border: none;
      background: lighten($cnc-yellow, 22%) !important;
      padding-left: 2rem;
      width: 12rem;

      @include mq($screen-md) {
        width: 18rem;
      }
    }

    &:before {
      font-family: 'FontAwesome';
      content: "\f002";
      color: $gray-light;
      display: block;
      position: absolute;
      width: 1rem;
      height: 1rem;
      z-index: 10;
      padding: .25rem .5rem;
    }
  }

  &__right {
    @extend .col, .col-md-4, .align-self-center, .clearfix;
    text-align: right;
  }


  &__user {
    &-dropdown {
      float: right;

      a.nav-main__user-name {
        color: $link-color;
      }

      .dropdown-menu {
        display: block;
        visibility: hidden;
        opacity: 0;
        top: 60%;
        transition: all 0.2s  ease;
        min-width: 8.5rem;
        left: auto;
        right: 0;

        // guaranteeing there is enough space for the offset: xl is also max content width + amount left the dropdown is offset + a little extra to account width of a scroll bar if it's present.
        @include mq(calc($screen-xl + 1.5rem + 30px)) {
          left: -1.5rem;
          right: auto;
        }
      }

      &.show {
        .dropdown-menu {
          visibility: visible;
          opacity: 1;
          top: 100%;
        }
      }

      a.dropdown-item {
        text-transform: normal;
        color: $link-color;
      }
    }

    &-name {
      font-size: $font-size-sm;
      font-weight: 600;
      text-transform: uppercase;
    }

    &-role {
      display: block;
      width: 100%;
      font-size: $font-size-xs;
      text-align: left;
      text-transform: uppercase;
    }
  }


  &__btn {
    &-signup {
      padding-right: 15px;
    }
  }

}

