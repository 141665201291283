.hidden {
	display: none;

	// Flatpickr also uses the `.hidden` class to space out its calendar days,
	// so we need to exclude it from this rule.
	&.flatpickr-day {
		display: block;
	}
}

.border-bottom {
	border-bottom: 1px solid $border-color;
	padding-bottom: 1rem !important;
	margin-bottom: .75rem;
}