

.onboarding-container {
	@extend .jumbotron;
	padding: 0 0 1.5rem;
}

.onboarding-indicators {
	list-style: none;
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
	margin: 3rem 0 0;
	padding: 0 .5rem;
	counter-reset: steps;

	li {
		position: relative;
		display: inline-flex;
		align-content: center;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		height: 3.25rem;
		width: 3.25rem;
		border-radius: 50%;
		border: 4px solid rgba($gray-light, 0.45);
		color: rgba($gray-light, 0.45);
		background-color: transparent;
		font-size: 1.5rem;
		text-align: center;
		margin: 0 10px 1.8rem;
		counter-increment: steps;

		@include media-breakpoint-up(sm) {
			margin-bottom: 1rem;
		}

		&:hover {
			cursor: pointer;
		}

		&::before {
			content: 'Step 'counter(steps);
			font: {
				size: 0.9rem;
			}
			text-transform: uppercase;
			display: block;
			width: 100%;
			position: absolute;
			top: -27px;
			width: 3.3rem;
		}

		&::after {
			font-family: 'fontAwesome';
			content: '\f00d';
			display: block;
			width: 100%;
		}

		&.is-done {
			color: $brand-success;
			border-color: $brand-success;

			&::after {
				content: '\f00c';
				color: $brand-success;
			}
		}

		&.active {
			border-color: $cnc-blue;
			color: $cnc-blue;

			&.is-done {
				color: $brand-success;
				border-color: $brand-success;
			}
		}
	}
}

.onboarding-step {
	flex-wrap: wrap;
}

.onboarding-step-content {
	display: block;
  width: 100%;
	padding: 0 1rem 1rem;
	margin-bottom: 1rem;

	@include media-breakpoint-up(lg) {
		width: 60%;
		margin: auto;
	}
}

.onboarding-step-heading {
  text-align: center;
	text-transform: uppercase;
	display: block;
	color: $cnc-blue;
	border-bottom: 1px solid $border-color;
	padding-top: 1rem;
	padding-bottom: .75rem;
	margin-bottom: 1rem;
}

.onboarding-step-action {
	display: flex;
	width: 100%;
	align-content: center;
	justify-content: center;
	align-items: center;
	margin: auto auto 1.5rem;
}
