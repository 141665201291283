.mentor-transer__cell {
	&-name,
	&-mentor {
		min-width: 12rem;
		max-width: 15rem;
	}

	&-program {
		min-width: 15rem;
		max-width: 15rem;
	}
}