#preview {
  margin-top: 40px;

  .back {
    background: #3288e6;
    color: white !important;
    padding: 8px 20px;
    -webkit-box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    margin-bottom: 20px;
  }
}