.orgs-list-group {
	@extend .list-group;
}

.orgs-list-group-item {
	@extend .list-group-item;

	@include hover-states {
		border-bottom-color: $cnc-blue;
		background-color: none;
	}

	> span {
		color: $gray;
	}

	&.active,
	&:hover,
	&.active {
		background: none;
		border-color: $border-color;
	}

	&.active {
		> a,
		> span {
			font-weight: bold;
			color: $cnc-tan;
		}

		&::before {
			color: $cnc-tan;
		}
	}

	&:before {
		color: $gray;
	}
}

.orgs-list-group-item-master {
	@extend .list-group-item;
	color: $cnc-tan;

	a,
	&.active {
		color: $cnc-tan;
		font-weight: bold;
	}

	> a {
		color: $cnc-tan;
		font-weight: bold;
	}
}

.orgs-list-group-item-indented {
	display: block;
	padding-left: 1.75rem;

	> ul {
		margin: 0 0 0 1.05rem;
		padding: .5rem 0 0;

		li {
			padding: .5rem;
			border: 0;
			border-top: 1px dotted $border-color;
		}

		ul {
			padding-left: 1rem;
			margin-top: .3rem;
			margin-bottom: -5px;
		}
	}

	li {
		display: block;
	}

	&:before {
		font-family: 'fontAwesome';
		content: '\f041';
		display: inline-block;
		padding-right: 5px;
	}

	&-2 {
		padding-left: 3.85rem;

		&:before {
			font-family: 'fontAwesome';
			content: '\f276';
			display: inline-block;
			padding-right: 5px;
		}
	}
}