@for $i from 1 through 3 {
	h#{$i} {
		color: $cnc-blue;
	}
}

.heading {

	&__section {
		@extend .h3;
		color: $gray-light;
		border-bottom: 1px solid $gray-lighter;
		margin-bottom: 2.25rem;
		padding-top: 2rem;
		padding-bottom: .75rem;
		text-transform: uppercase;
		width: 100%;

		&.small {
			@extend .h4;
		}
	}
}

.text {
	&-bold {
		font-weight: 600;
	}

	&-normal {
		font-weight: 300;
	}

	&-tan {
		color: $cnc-tan;
	}

	&-sm {
		font-size: $font-size-sm;
	}

	&-super {

	}
}