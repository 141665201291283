/*  Student Detail Header
	------------------------------------ */

.student-detail__header {
	padding-top: 0;
	border-bottom: 0;

	&-avatar {
		margin-right: 1.5rem;
	}

	&-verify {
		color: $brand-danger;
		font-size: $font-size-base;
		text-transform: uppercase;
		vertical-align: middle;
	}

	h3 {
		color: $gray;
	}

	dl {
		color: $gray;
		margin-bottom: 0;
		padding-bottom: 0;
		line-height: 1;

		dt {
			padding-right: .5rem;
			text-transform: uppercase;
			font-weight: 300;

			&:after {
				content: ": ";
			}
		}

		dd {
			font-weight: 300;
			padding-right: .75rem;
			margin-right: .75rem;
			border-right: 1px solid $border-color;

			&:last-child {
				border-right: 0;
			}
		}
	}
}

/*  Student Detail
	------------------------------------ */

.student-detail {
	@extend .col-12, .no-gutters;
	padding: 0;
	background-color: $card-cap-bg;
	margin-bottom: 2rem;

	@include mq($screen-md) {
		padding: 1rem 0 1rem 2.5rem;
	}

	&__name {
		padding-top: 1rem;
		padding-bottom: 0;
		margin-bottom: 0;
	}


	&__headings {
		color: $gray-light;
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;

		> li {
			display: inline-block;
		}

		&-heading {
			font-weight: 600;
			color: $gray-light;
		}
	}

	.card-header {
		border: none;
		@extend .h1;
		text-align: center;

		@include mq($screen-md) {
			text-align: left;
		}
	}

	&__avatar {
		display: inline-block;
		font-size: 6rem;
		width: 10rem;
		height: 10rem;
		line-height: 9.5rem;
		text-align: center;
		vertical-align: middle;
		color: $white;

		&.medium {
			width: 6rem;
			height: 6rem;
			line-height: 5.5rem;
			font-size: 4rem;
		}

		&.circle {
			border-radius: 100%;
		}

		&.square {
			border-radius: $border-radius;
		}

		&.octagon {
			position: relative;

			&.medium {
				&:before,
				&:after {
					border-width: 1.45rem;
					border-left-color: $white;
					border-right-color: $white;
					width: 6rem;
				}
			}

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				border-bottom: 2.75rem solid $red;
				border-left: 2.75rem solid $card-cap-bg;
				border-right: 2.75rem solid $card-cap-bg;
				width: 10rem;
				height: 0;
			}

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				border-top: 2.75rem solid $red;
				border-left: 2.75rem solid $card-cap-bg;
				border-right: 2.75rem solid $card-cap-bg;
				width: 10rem;
				height: 0;
			}


		}

		&.red {
			background-color: $red;

			&:before {
				border-bottom-color: $red;
			}

			&:after {
				border-top-color: $red;
			}
		}

		&.green {
			background-color: $green;

			&:before {
				border-bottom-color: $green;
			}

			&:after {
				border-top-color: $green;
			}
		}

		&.blue {
			background-color: $cnc-blue;

			&:before {
				border-bottom-color: $cnc-blue;
			}

			&:after {
				border-top-color: $cnc-blue;
			}
		}

		&.yellow {
			color: $gray;
			background-color: $cnc-yellow;

			&:before {
				border-bottom-color: $cnc-yellow;
			}

			&:after {
				border-top-color: $cnc-yellow;
			}
		}

		&.orange {
			color: $gray;
			background-color: $cnc-orange;

			&:before {
				border-bottom-color: $cnc-orange;
			}

			&:after {
				border-top-color: $cnc-orange;
			}
		}
	}
}

.student-detail__info {

	@extend .row, .no-gutters;

	&-row {
		@extend .row, .no-gutters;
		padding: 10px 0;
		margin-left: 1rem;
		margin-right: 1rem;
		border-bottom: 1px solid $border-color;
		color: $gray-light;
	}

	&-body {
		@extend .col-12, .col-md-8, .align-self-start;

		@include mq($screen-md) {
			padding-left: 2rem;
		}
	}

	&-progress {
		margin: 0 1rem;
	}

	&-gpa {
		@extend .d-flex, .align-self-center;
		min-width: 220px;

		&-heading {
			text-align: center;
			font-size: $font-size-lg;
			margin-bottom: 0;
		}
	}
}

/*  Student Credits
	------------------------------------ */

.student-credits {
	@include mq($screen-sm) {
		padding: 0 1.5rem;
	}

	@include mq($screen-xl) {
		padding-top: 1.5rem;
		padding-bottom: 5rem;
	}

	&__header {
		@extend .col-12;
	}

	&__edits,
	&__add {
		@extend .col-12, .col-md-6;
	}

	&__edits {

		.student__table {
			tbody {
				max-height: 35rem;
				overflow-y: scroll;
				-webkit-overflow-scrolling: touch;
			}
		}
	}

	&__add {

		label[for="id_gpa"] {
			text-transform: uppercase;
		}
	}
}

/*  Students Notes and Edit Student
	------------------------------------ */

.student-update {
	margin-top: 2rem;
}

.student-update,
.student-create {
	.form-group.no-label {
		padding-top: .8rem;
		.checkbox {
			padding-top: 1.5rem;
		}
	}
}

.student-notes,
.student-update {
	padding-bottom: 3rem;

	@include mq($screen-sm) {
		padding: 0 1.5rem 3rem;
	}

	@include mq($screen-xl) {
		padding: 0 2rem 3rem;
	}

	&__header {
		@extend .col-12;
	}

	&__notes,
	&__add {
		@extend .col-12, .col-md-6;
	}

	&__add {
		padding-bottom: 3rem;

		.form-group .checkbox {
			padding-top: .5rem;
		}
	}

	&__list {
		max-height: 35rem;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		margin-left: 0;
		padding-left: 0;

		&-header {
			display: inline-block;
			vertical-align: middle;
		}

		&-title {
			@extend .h6;
			display: inline-block;
		}

		&-actions {
			display: inline-block;
			margin-left: 1rem;

			&-item {
				display: inline-block;
				padding: 0 .25rem;
			}
		}

		&-item {
			@extend .list-group-item, .flex-column, .align-items-stretch;
			flex-basis: 100%;
			width: 100%;


			&.private {
				@extend .list-group-item-warning;
			}
		}
	}

}