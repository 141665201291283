label {
  font-weight: bold;
  color: $gray;
}

.form-group {
	&.no-label,
	.checkbox {
		@include mq($screen-sm) {
		  padding-top: 2rem;
		}
	}

	&.pt-1 .checkbox{
		padding-top: 0;
	}

	select {
		border-radius: 0;
	}
}

.required-field {
	&:before {
		display: inline-block;
		content: "*";
		@extend .text-danger;
		padding-right: .15rem;
	}
}


.cnc-form {

	&-password {
		p {
			@extend .form-group;

			input {
				@extend .form-control;
			}
		}

		.errorlist {
			@extend .list-unstyled;
			background: $alert-danger-bg;
			color: $alert-danger-text;
			border: 1px solid $alert-danger-text;
			padding: .25rem .5rem;
		}
	}

	&--inline {

		.form-group {
			margin-right: $form-label-margin-right;

			@include mq($screen-md) {
				margin-bottom: 0;
			}
		}
	}

	&__label {

		&--inline {
			margin-right: $form-label-margin-right;
		}
	}

	&__button {
		@extend %button, %button-primary;
		margin-top: 15px;

		@include mq($screen-sm) {
			margin-top: 0;
		}
	}

}

.form-control {
	border-radius: 0;
}

.required label::after {
  content: " *";
  color: $red;
}


@keyframes shake {
	10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.form-group {
	&.highlight-shake {
		border: 1px solid $brand-danger;
		padding: .5rem;
		animation: shake 1s ease-out 1s forwards;
		backface-visibility: hidden;
		perspective: 1000px;

		label {
			color: $alert-danger-text;
		}
	}
}

/* small change for label display on mentor/admin form */
.organizations-entry {
	.input-group {
		flex-wrap: wrap;

		.input-group-prepend {
			width: 100%;
		}
	}
}