.notifications {

	&.is-empty {
		.cnc-card__header {
			background: $alert-success-bg;
			color: $alert-success-text;
			border-bottom: 0;
		}

		.cnc-card__title,
		.cnc-card__header-actions .button {
		  color: $alert-success-text;
		}
	}

	&.is-important {
		.cnc-card__header {
			justify-content: flex-start;
			background: $alert-danger-bg;
			color: $alert-danger-text;
		}

		.cnc-card__title,
		.cnc-card__header-actions .button {
		  color: $alert-danger-text;
		}
	}

	&.resolved {
		.cnc-card__header {
			justify-content: flex-start;
			background: $cnc-blue;
			color: $white;
		}

		.cnc-card__title,
		.cnc-card__header-actions .button {
		  color: $white;
		}
	}

	.cnc-card__header {
	  justify-content: flex-start;
	  background: $alert-warning-bg;
	  color: $alert-warning-text;
	 }

	.cnc-card__title {
		color: $alert-warning-text;
		text-transform: none;
	}

	.cnc-card__header-actions {
		position: absolute;
		top: .25rem;
		right: 1.5rem;

		.button {
			min-width: 2rem;
			max-width: 2rem;
			margin: 0;
			font-size: $font-size-lg;
			color: $alert-warning-text;
		}
	}

	&__block {
		&.is-opened {
			height: 15rem;
			overflow-y: scroll;
		}
	}

	&__list {
		&-item {
			@extend .list-group-item;
			border-radius: 0 !important;

			p {
				margin-bottom: 0;
			}

			.resolved-date {
				font-size: .75rem;
				font-style: italic;
				padding-right: .5rem;
			}
		}
	}
}

.notifications-tabs {

	.nav-item > a {
		&.cnc-notifications {

			background: fade-out($alert-warning-bg, .5);
			color: $alert-warning-text;

			&.active {
				background: $alert-warning-bg;
				color: $alert-warning-text;
			}
		}

		&.cnc-resolved-notifications {

			background: fade-out($cnc-blue, .5);
			color: $white;

			&.active {
				background: $cnc-blue;
				color: $white;
			}

		}
	}
}

.notifications-tab-content {
	.tab-pane {
		max-height: 15rem;
		overflow-y: scroll;
	}
}