
/*  USAGE

	<div class="icon icon--{name}">
		<span class="sr-only">{name}</span>
	</div>

	NOTE: Icons are in alphabetical order
	------------------------------------ */

.icon {
	@extend %fa;
	display: inline-block;
	line-height: 1;
	vertical-align: bottom;
	width: 1.8rem;

	&:after {
		font-size: 1.15rem;
		line-height: 1.15rem;
	}

	&__empty {
		width: 1.8rem;

	}

	&__focus-area {
		text-align: center;

		&:after {
			content: "\f06e";
			color: $cnc-blue;
			font-size: 1.05rem;
		}
	}


	&__progressing {
		&:after {
			content: "\f139"; //fa-chevron-circle-up
			color: $brand-success;
		}
	}

	&__regressing {
		&:after {
			content: "\f13a"; //fa-chevron-circle-down
			color: $brand-warning;
		}
	}

	&__sortby {
	  &-alpha {
	    &:after {
	      content: "\f15d"; //fa-sort-alpha-asc
	    }

	    &--reversed {
	      &:after {
	        content: "\f15e"; //fa-sort-alpha-desc
	      }
	    }
	  }

	  &-num {
	    &:after {
	      content: "\f162"; //fa-sort-numberic-asc
	    }

	    &--reversed {
	      &:after {
	        content: "\f163"; //fa-sort-numberic-desc
	      }
	    }
	  }
	}

	&__unchanged {
		&:after {
			content: "\f111"; //fa-circle
			color: $text-muted;
			font-size: 0.65rem;
		}
	}
}