.training-list {
    ul {
        li {
            padding: 7px 0;
            margin: 0;
            list-style: none;
            border-bottom: 1px dotted #cfcfcf;
            display: flex;
            justify-content: space-between;
            position: relative;

            &:last-of-type {
                border-bottom: 0;
            }

            @media(max-width: 980px) {
                display: block;
                padding: 10px 0;
            }

            .video-info {
                align-self: center;
            }

            h5 {
                padding: 0;
                margin: 0;
                padding-left: 37px;
            }

            p {
                margin: 0;
                padding: 5px 30px 0 37px;
                font-size: 14px;
            }

            i {
                position: absolute;
                left: 0;
            }

            .btn {
                padding: 6px 15px;

                @media(max-width: 980px) {
                    margin-top: 10px;
                    margin-left: 37px;
                }
            }
        }
    }
}

.training-video-header {
    padding: 1rem;
    margin-left: 15px;
}

.training-detail {
    .container {
        @media(max-width: 1140px) {
            margin: 0;
        }
    }

    iframe {
        width:100%; 
        border: none; 
        min-height: 600px; 
        background: $white;
        border-bottom: 5px solid #efefef;
        border-top: 5px solid #efefef;

        @media(max-width: 980px) {
            min-height: 500px;
        }

        @media(max-width: 420px) {
            min-height: 0;
        }
    }

    .video-info {
        padding: 10px 0 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media(max-width: 980px) {
            display: block;
        }

        h5 {
            margin: 0;
            font-size: 20px;
            line-height: 1;
        }

        p {
            margin: 0;
        }

        i {
            margin-right: 3px;
        }

        .back-to-all {
            .btn {
                font-size: 13px;
                padding: 6px 12px;
                margin-right: 20px;
            }

            @media(max-width: 980px) {
                text-align: left;
                margin-bottom: 10px;
            }
        }

        small {
            @media(max-width: 980px) {
                display: block;
                margin-bottom: 5px;
            }
        }
    }
}