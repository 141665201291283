

.footer__main {
    //hide this for now since its getting in the way of forms
//    display: none;

	background: $gray;
	padding: 40px;
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center;
	&-container {
		@extend .container;
	}

	&-row {
		@extend .row;
	}

	&-content {
		@extend .col;
		color: $white;

		p {
			padding-top: 20px;
			font-size: $font-size-xs;
		}

		a {
			color: $white;
			text-decoration: underline;

			&:active,
			&:focus,
			&:hover {
				text-decoration: none;
			}
		}
	}
}

