.temparary-menu-items {
  list-style: none;
  padding-left:0;
  margin-left:0;

  li {
    display: inline-block;;
    padding: 0 10px;
    border: 1px solid $gray-lightest;
  }
}
.mentor-list-actions {
  list-style: none;
  margin:0;
  padding:0;
}

.mentor-row {
  padding: 10px 0;
}

.mentor-row:nth-child(2n) {
  background:$gray-lightest;
}
