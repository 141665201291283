@keyframes fadeout-slideup {
  0%, 75% {
    opacity: 1;
    margin: 0;
    transform: translateY(0);
  }

  80% {
    opacity: 0;
  }

  95%, 100% {
    opacity: 0;
    margin-bottom: -100px;
    transform: translateY(-100px);
  }
}

.messages {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 1029;
	opacity: 0.95;

	.alert-error {
		@extend .alert-danger;
  }
  
  .alert-success {
    @extend .alert-success;
    color: $black;
  }
}

.alert {
	p:last-child {
		margin-bottom: .25rem;
	}

  a:not(.btn, .button) {
    text-decoration: underline;
  }
}