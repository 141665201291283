.org-container {
  width: 100%;
  padding: 15px 15px;

  @include mq($screen-md) {
    max-width: $screen-xl;
    margin: 0 auto;
    padding-bottom: 8rem;
  }
}

.model-link {
  text-decoration: underline;
}

.update-message-wrapper {
  margin-top: 3em;
  &:first-child {
    margin-top: 0;
  }

  h6 {
    color: $cnc-blue;
    font-weight: bold;
  }
}
