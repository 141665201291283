@keyframes pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.9);
  }
}

#utilities-container {
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 1010;

  > * {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover,
    &:focus {
      animation: pulse 0.5s infinite alternate;
    }
  }
} 

.tour {
  &-link {
    display: inline-block;
    background-color: rgba($cnc-blue, 0.75);
    color: $gray-lightest;
    font-size: 1rem;
    height: 4rem;
    width: 4rem;
    vertical-align: middle;
    border-radius: 100%;
    text-align: center;
    box-shadow: 6px 6px 11px -6px rgba(0,0,0,0.44);
    padding: .5rem .25rem;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      background-color: $gray-lightest;
      color: $brand-primary;
    }
  }

  &-text {
    display: block;
    text-transform: uppercase;
    font-size: .8rem;
  }
}

.introjs {
  &-overlay {
    z-index: 1020;
  }

  &-tooltip {
    z-index: 99999;

    @include mq($screen-md) {
      min-width: 250px;
      max-width: 400px;
    }

    p {
      margin-top: 1.5rem;
    }
  }

  &-tooltiptext {
    font-size: $font-size-sm;
  }
}

.introjsFloatingElement {
  top: 12% !important;
}