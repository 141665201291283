.dashboard {

  &__container,
  &__content {
    width: 100%;
    padding: 15px 15px 5rem;

    @include mq($screen-md) {
      max-width: $screen-xl;
      margin: 0 auto;
      padding-bottom: 8rem;
    }
  }

  &__headings {
    text-transform: uppercase;
  }

  &__form {
    &-label {
      display: block;
      color: $form-label-color;
      font-weight: bold;
      text-transform: uppercase;
    }

    &-label-thin {
      display: block;
      color: $form-label-color;
      font-weight: lighter;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    &-btn {
      display: none;
      min-width: 4rem;
      margin-top: -3px;

      @include mq($screen-sm) {
        margin-top: 0;
      }
    }
  }

  &__header {
    position: relative;
    padding-bottom: .5rem;
    margin-bottom: .5rem;
    background-color: $gray-lightest;
    border-bottom: 1px solid $gray-lighter;

    @include mq($screen-lg) {
      padding-bottom: 0;
    }
  }

  &-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: .5rem 1rem 0;
    max-width: $screen-xl;

    &__group {
      display: flex;
      align-items: center;
    }

    &__item {
      .form-control {
        border-radius: 0;
        appearance: none;
        background-position: right 50%;
        background-repeat: no-repeat;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
        padding: .5rem;
        padding-right: 1.8rem;
        min-width: 10rem;
        font-size: $font-size-sm;

      }
    }

    &__form {
      &-label {
        display: none;
        color: $form-label-color;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;
        font-size: $font-size-sm;

        @include mq($screen-md) {
          display: block;
        }
      }

      &-btn {
        display: none;
        min-width: 4rem;
        margin-top: -3px;

        @include mq($screen-sm) {
          margin-top: 0px;
        }
      }
    }

    &.org,
    &.mentors {
      padding-bottom: 10px;

      @include mq($screen-md) {
        padding-bottom: 0;
      }
    }

  }

  &__data {
    @include mq($screen-sm) {
      margin-top: 2rem;
    }
  }

  &__actions {
    display: block;
    padding-bottom: 15px;
  }

  &__calendar {
    &-form {
      width: 100%;
      background-color: $gray-lighter;
      padding: 2%;

      button {
        min-width: 125px;
      }

      input {
        background-color: white !important;
        border: 0;
        padding: 10px;
        font-size: $font-size-lg;
      }

      i {
        font-size: $font-size-lg;
        margin-right: 10px;

        &:hover {
          cursor: pointer;
        }
      }

      .form-group {
        margin-bottom: 0;
        margin: 0 10px;
      }

      .flex-spacing {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

}

// Dashboard Sticky Items

.student-data-stuck {
  display: none;
  transition: 1s all;

  .dashboard-nav {
    border-top: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .stuck-student-info {
    display: flex;
    align-items: center;
  }

  .student-info-small {
    margin-left: 1rem;
    color: $gray;
    font-size: .75rem;
  }

  .stuck-actions {
    i {
      padding-right: 0;
    }
  }

  h3 {
    color: $gray;
  }
}

.dashboard__header{
  &.is-stuck {
    .student-data-stuck {
      display: block;
    }
  }
}

/*  Dashboard Tables
  ------------------------------------ */

.dashboard {
  &-data {

    &__table {
      @extend .table, .table-responsive, .table-hover;

      @include mq($screen-md) {
        display: table;
      }

      th {
        white-space: nowrap;
        text-transform: uppercase;
      }

      th,
      td {
        font-size: $font-size-sm;
        vertical-align: middle;
      }



      th:nth-child(n+3),
      td:nth-child(n+3) {
        text-align: center;
      }
    }

    &__student-avatar {
      display: inline-block;
      font-size: 1rem;
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      vertical-align: middle;
      color: $white;
      margin-right: .25rem;

      &.circle {
        border-radius: 100%;
      }

      &.square {
        border-radius: $border-radius;
      }

      &.octagon {
        position: relative;

        &:before,
        &:after {
          content: "";
          position: absolute;
          left: 0;
          width: 2rem;
          height: 0;
        }

        &:before {
          top: 0;
          border-bottom: .5rem solid $red;
          border-left: .5rem solid $white;
          border-right: .5rem solid $white;
        }

        &:after {
          bottom: 0;
          border-top: .5rem solid $red;
          border-left: .5rem solid $white;
          border-right: .5rem solid $white;
        }
      }

      &.red {
        background-color: $red;

        &:before {
          border-bottom-color: $red;
        }

        &:after {
          border-top-color: $red;
        }
      }

      &.green {
        background-color: $green;

        &:before {
          border-bottom-color: $green;
        }

        &:after {
          border-top-color: $green;
        }
      }

      &.blue {
        background-color: $cnc-blue;

        &:before {
          border-bottom-color: $cnc-blue;
        }

        &:after {
          border-top-color: $cnc-blue;
        }
      }

      &.yellow {
        color: $gray;
        background-color: $cnc-yellow;

        &:before {
          border-bottom-color: $cnc-yellow;
        }

        &:after {
          border-top-color: $cnc-yellow;
        }
      }

      &.orange {
        color: $gray;
        background-color: $cnc-orange;

        &:before {
          border-bottom-color: $cnc-orange;
        }

        &:after {
          border-top-color: $cnc-orange;
        }
      }
    }

    &__cell {

      &-totals {
        min-width: 6rem;
      }

      &-name {
        min-width: 10rem;
      }

      &-actions {
        min-width: 7rem;
        max-width: 9rem;
        text-align: center;
        border-left: 1px solid $table-border-color;
        border-right: 1px solid $table-border-color;

        button.dropdown-toggle {
          font-size: $font-size-sm;
        }
      }
    }

    &__totals {
      border-top: 3px solid darken($border-color, 25%);

      > td {
        border: none;
        font-weight: bold;
        font-size: $font-size-xs;
        text-transform: uppercase;
        text-align: center;

        &.dashboard-data__cell-name {
          text-align: left;
        }
      }
    }
  }

}

.inactive-student {
    .top {
      font-size: 1.1rem;
    }

    .bottom {
      font-size: .75rem;
    }
}


.dashboard {
  &-admin
  &-mentor {
    padding-top: 0;
  }

  &-admin {
    &__credits,
    &__mentors {
      margin-top: 3rem;
    }

    &__mentors {
      &-table {
        display: table;
        th {
          position: sticky;
          z-index: 10;
          border-top: 0;
          top: 71px;

          @include mq($screen-md) {
            top: 92px;
          }
          @include mq($screen-lg) {
            top: 84px;
          }
        }
      }
    }
  }

  &__headings {
    padding-bottom: 1rem;
  }
}

.dashboard-admin {
  .cnc-card__header-col {
    min-width: 12rem;
    max-width: 12rem;
  }

  &__form-search {
    width: 12rem;
  }

  &__search {
    padding: 0 1rem 1rem;

    .form-group,
    input,
    label,
    select {
      @include mq-down(613px) {
        display: block !important;
        width: 100% !important;
        margin-bottom: .25rem;
      }
    }

    .button {
      padding: 0.6rem 1rem;
    }
  }

  &__card {
    .cnc-card__header {
      text-align: center;

      @include mq($screen-sm) {
        display: flex;
        align-items: center;
        text-align: left;
      }
    }

    .cnc-card__header-actions {
      text-align: center;

      .button {
        margin: 0 auto;
      }
    }

  }

  &__avatar {

    img {
      margin-right: 1rem;
      width: 100%;
      height: 100%;
      max-width: 6rem;
      max-height: 6rem;
      margin: 0 auto 1rem;

      @include mq($screen-sm) {
        margin: 0 1rem 0 0;
      }
    }

    .cnc-card__title {
      display: inline-block;
      max-width: 82%;
    }

  }
}

#spinnerLoader {
  background: rgba(0,0,0,.5);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: none;

  .fa-spinner {
    color: white;
    font-size: 4rem;
    position: fixed;
    top: 50%;
    left: 50%;
  }
}

.calendar-key {
  &-schoolday {
    color: $cnc-blue-dark;
  }
  &-holiday {
    color: $cnc-yellow;
  }
}
