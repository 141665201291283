//CALENDARS LIST

.calendars {
  overflow-x: auto;
}

.calendar-list {
  th {
    font-weight: bold;
    text-transform: uppercase;
  }

}

.calendar-list__name {
  min-width: 20rem;
  width: 20rem;

  a {

    &:after {
      display: none;
      font-family: 'fontAWesome';
      content: '\f040';
      margin-left: .25rem;
      color: $cnc-blue;
    }

    @include hover-states {
      &:after {
        display: inline-block;
      }
    }
  }
}

.calendar-list__date {
  min-width: 8rem;
  width: 8rem;
}

.calendar-list__usedby {
  min-width: 18rem;
  width: 18rem;

  ul {
    padding-left: 1.25rem;
  }
}

.calendar-list__actions {
  min-width: 14rem;
  width: 14rem;
}

//BEGIN SCHOOL CALENDARS

.school-year {

  .calendar__day {

    cursor: pointer;

    &.selected {

      background: $cnc-blue-dark;
      color: white;
    }
  }

  &__calendar {
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;

    @include mq($screen-sm) {
      text-align: left;
    }

    .nav-tabs {
      margin-bottom: 1rem;
      border-bottom: 5px solid $gray-light;

      .nav-item {
        margin-bottom: 0;
        margin-left: .5rem;
      }

      .nav-link {
        border-radius: 0;
        background-color: $gray-lighter;
        color: $gray;

        &.active {
          background-color: $gray-light;
          border-color: $gray-light;
          color: $white;
        }
      }
    }

    .nav-item {
      &:first-child {
        margin-left: 1rem;
      }
    }

    .tab-content {
      padding: 1rem 0;
      text-align: center;
    }

    table {
      @extend .calendar;

      th {

        &.month {
          span {
            display: inline-block;
            line-height: 1;
            padding-top: 0.675rem;
          }

          button {
            background-color: $cnc-blue-lightest;
            cursor: pointer;
            padding-right: 0.8rem;
            padding-left: 0.8rem;

            &.month-check-default {
              float: left;
            }

            &.month-uncheck-all {
              float: right;
            }
          }
        }
      }
    }
  }
}
