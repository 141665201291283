.infographic {
  width: 80%;
  margin: 0 auto;

  .row {
    border-bottom: 1px solid $gray-lighter;
    padding: 30px 0;

    &:last-of-type {
      border-bottom: 0;
    }

    .info-item-header {
      margin-bottom: 30px;
      font-weight: 400;
    }

    .info-item {
      border-right: 1px solid $gray-lighter;
      padding: 0 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        font-weight: 200;
        font-size: .75rem;
      }
      h4 {
        color: $gray-light;
        font-weight: 200;
      }

      .explainer-text {
        font-size: 1.25rem;
        color: $gray-light;
        font-weight: 400;
      }

      svg {
        max-width: 72px;
      }

      h5 {
        color: rgba(6, 119, 210, 0.75);
        font-size: 3.5rem;
        font-weight: 800;

        &.larger {
          font-size: 4.5rem;
        }

        i {
          font-weight: normal;
          color: $gray;
          fill: $gray;
        }
      }

      .info-tooltip {
        font-size: 16px;
      }

      &:last-of-type {
        border-right: 0;
      }
    }
  }
}
