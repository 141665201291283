.page-item {

	&.next.disabled,
	&.prev.disabled {
		a {
			position: relative;
		    display: block;
		    padding: 0.5rem 0.75rem;
		    margin-left: -1px;
		    line-height: 1.25;
		    color: #23527c;
		    background-color: #fff;
		    border: 1px solid #ddd;
		    border-top-left-radius: $border-radius;
		    border-bottom-left-radius: $border-radius;

		    pointer-events: none;
		    cursor: not-allowed;
		    background-color: #fff;
		    border-color: #ddd;
		}
	}

	.next {
	  margin-right: 3px;
	}

	.prev {
	  margin-right: 3px;
	}
}
