/*  PROGRAM NAV / HEADER
	------------------------------------ */

.program__nav {
	display: block;
	max-width: 1140px;
	margin: 0 auto;
	padding: 0;
	text-align: center;
	border-top: 1px solid $border-color;

	@include mq($screen-sm) {
		padding: 1.5rem 1rem 0;
		margin-bottom: 1.5rem;
	}

	.nav-tabs {
		padding-left: 1rem;
		border-color: $cnc-blue-lighter;

		.nav-item {
			margin-right: .25rem;
		}

		.nav-link {
			text-transform: uppercase;

			@include hover-states {
				color: $cnc-tan;
				border-bottom-color: $white;
			}

			&.active {
				border-color: $cnc-blue-lighter;
				border-top: 2px solid $cnc-blue;
				border-bottom-color: $white;
				font-weight: 600;
				color: $gray;
				box-shadow: 2px -2px 2px 0px rgba(0,0,0,0.2);
			}
		}
	}

	.button {
		font-size: 0.75rem;
		margin-bottom: .45rem;
		margin-right: .15rem;
		width: 45%;

		@include mq($screen-sm) {
			width: 32%;
		}

		@include mq($screen-md) {
			min-width: 9.8rem;
			max-width: 9rem;

			&:first-child {
				min-width: 10.5rem;
			}
		}

		@include mq($screen-xl) {
			margin-bottom: 0;
		}

		&.is-active {
			@extend .button-primary;
		}
	}
}


/*  PROGRAM CONTENT
	------------------------------------ */

.program {
	width: 100%;
	padding: 15px;

	@include mq($screen-md) {
		max-width: $screen-xl;
		margin: 0 auto;
	}

	&-detail {
		@extend .row, .no-gutters;
	}

	&-graduation {
		margin-bottom: 2rem;
		&__gpa {
			@extend .col-sm-4, .align-self-center, .d-flex;
			justify-content: center;
		}

		&__credits {
			@extend .col-sm-8, .align-self-center;
		}
	}

}

.program-report {
	.input-daterange {
		display: inline-flex;
		min-width: 20rem;
		max-width: 20rem;
	}

	.cnc-card__header {
		display: block;

		@include mq($screen-md) {
			display: flex;
			justify-content: flex-start;
		}

		&.subhead {
			position: relative;
			justify-content: flex-start;
			margin-bottom: 2rem;

			.program-report__toplink {

				@include mq($screen-sm) {
					position: absolute;
					top: 0;
					right: 1rem;
					padding: 1rem 0;

					&.connect {
						margin-top: -0.5rem;
					}
				}

				a {
					@extend .button, .button-secondary;
					min-width: 7rem;
					max-width: 7rem;
				}
			}
		}
	}

	.cnc-card__title {
		display: block;
		width: 100%;
		margin-bottom: .5rem;

		@include mq($screen-md) {
			display: inline-flex;
			width: auto;
			margin-bottom: 0;
		}
	}

	.cnc-card__header-actions {
		display: block;
		width: 100%;

		.form-inline {
			display: block;
		}

		.button {
			max-width: 100%;
			width: auto;

			i {
				margin-right: 0;
			}
		}

		@include mq($screen-md) {
			display: inline-flex;
			width: 45rem;
		}
	}
}

.program-report__header {
	display: block;
	width: 100%;
	border-bottom: 1px solid $border-color;
	margin-bottom: 2rem;

	@include mq($screen-lg) {
		display: flex;
		align-items: center;
		align-content: center;
	}
}

.program-report__links {
	@include mq($screen-lg) {
		display: inline-flex;
		padding: .5rem;
		padding-left: 1rem;
		margin-bottom: 0;
		line-height: 2rem;
	}
}

.program-report__link {
	&-item {
		font-size: $font-size-base;
		font-weight: normal;
		text-transform: none;

		&:not(:last-child) {
			&:after {
				content: "|";
				display: inline-block;
				padding: 0 .25rem;
			}
		}

		a {
			display: inline-block;
			vertical-align: middle;
			line-height: 2rem;
			padding: .25rem 0;
		}
	}
}

.program-report__calendar {
	margin-bottom: 1rem;
	text-align: center;

	@include mq($screen-sm) {
		text-align: left;
	}

  table {
    @extend .calendar;
  }

	.calendar__tooltip {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		min-width: 200px;
		max-width: 200px;
		text-align:left;
		background: $tooltip-bg;
		border-radius: $border-radius;
		transform: translate3d(-16px, 30px, -1px);
		transition: all 0.2s ease-in-out;
	}

	.calendar__tooltip-container {
		position: relative;
		padding: .65rem;

		&:before {
			position: absolute;
			width: 0;
			height: 0;
			border-color: transparent;
			border-style: solid;
			top: -10px;
			left: 25px;
			content: "";
			border-width: 0 10px 10px;
			border-bottom-color: $tooltip-bg;
		}

  	.calendar__tooltip-title {
  		color: $white;
  		margin-bottom: .5rem;
  		text-transform: uppercase;
  		font-size: $font-size-sm;
  	}

  	.calendar__issues {
  		margin-bottom: 0;
  	  	padding-left: 1rem;

			li {
				color: $white;
				font-size: $font-size-xs;
			}
  	}
  }

  .has_issue {
  	color: $white;
  	position: relative;
  }

	.has_tooltip {
  	cursor: pointer;

		&:hover,
		&:focus {
			cursor: pointer;

			> .calendar__tooltip {
				z-index: 100;
				opacity: 1;
				transform: translate(-16px, 38px);
				color: $white;
			}
		}
	}
}

.calendar__legends {
	margin-top: 0;
	margin-bottom: 2rem;
	text-align: left;
	border-collapse: collapse;

	td {
		width: 20px;
		height: 20px;
		display: table-cell;
		text-align: center;
	}
}

.calendar__legends-heading {
	font-size: $font-size-xs;
	padding: 0 .5rem 0rem .25rem;
	display: block;
	text-align: center;

	@include mq($screen-xs) {
		display: inline-block;
	}
}

//Attendance issue classes
//a td can have zero, one, or many of these
//Order by the darkest color on top, and severity of the infractions

.attn_severity_5,
.behv_severity_5 {
  background: $darkred;
}
.attn_severity_4,
.behv_severity_4 {
  background: lighten($darkred, 20%)
}
.attn_severity_3,
.behv_severity_3 {
  background: lighten($darkred, 30%)
}
.attn_severity_2,
.behv_severity_2 {
  background: lighten($darkred, 40%)
}
.attn_severity_1,
.behv_severity_1 {
  background: lighten($darkred, 50%)
}

.program-report__chart {
	max-width: 100%;
	overflow-x: scroll;

	@include mq($screen-md) {
		overflow-x: visible;
	}

	svg {
		min-height: 250px;
		@include mq($screen-md) {
			min-height: 400px;
		}
	}
}

.program-report__notes {

	@include mq($screen-sm) {
		padding: .5rem;
	}

	.notes {
		margin-top: 1rem;
		margin-bottom: 2rem;
		h6 {
			display: block;
			width: 100%;
			font-size: $font-size-base;
			text-transform: uppercase;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}
}

.program-report__setfocus {
	display: inline-flex;
	vertical-align: middle;

	.form-group {
		margin-bottom: 0;

		&:before {
			@include mq($screen-sm) {
				content: "|";
				display: inline-block;
				padding: 0 1rem 0;
				font-weight: 200;
				line-height: 1rem;
				vertical-align: middle;
				height: 1rem;
			}
		}
	}

	label {
		font-size: $font-size-base;
		font-weight: 400;
		letter-spacing: 0;
		line-height: 1.5rem;
		margin-right: .45rem;

		.custom-control-indicator {
			top: .75rem;
			background-color: $white;
			border: 1px solid $border-color;
		}

		.custom-control-description {
			padding-top: .5rem;
			margin: 0;
		}
	}
}

.program-report__add-data {
	display: block;
	width: 100%;
	text-align: center;
}

.mentor-td {
	padding-bottom: 5px;
	padding-right: 10px;
}

#attendance,
#behavior,
#connect {
	td.student-data__cell-fieldname {
		padding-left: 0;
	}
}
