/*  Student Data
	------------------------------------ */

.student-data {
	&__nav {
		padding: 0 0 .5rem;
		justify-items: flex-start;

		@include mq(960px) {
			justify-items: space-between;
		}

		&-left,
		&-right {
			@extend .col-12, .col-sm-6, .col-md-5, .col-lg-3;

			.button {
				display: inline-block;
				width: 45%;
				margin-right: .5rem;

				@include mq($screen-lg) {
					width: auto;
					margin-right: .3rem;
				}
			}
		}

		&-left {
			padding: 0;
			order: 2;

			@include mq($screen-md) {
				min-width: 17rem;
			}

			@include mq($screen-lg) {
				order: 1;
			}
		}

		&-right {
			text-align: left;
			order: 2;
			padding: 0;
			margin-top: .5rem;

			@include mq($screen-sm) {
				margin-top: 0;
			}

			@include mq($screen-lg) {
				text-align: right;
				min-width: 17rem;
			}

			@include mq($screen-lg) {
				order: 3;
			}
		}

		&-title {
			@extend .col-12, .col-md-12, .col-lg-5, .col-xl-6;
			width: 100%;

			order: 1;
			padding: 0 0 1rem;

			@include mq($screen-lg) {
				order: 2;
				padding: 0;
				text-align: center;
			}
		}

		&-thisweek {
			padding-top: 0;
			.button {
				width: 45%;

				@include mq($screen-sm) {
					margin-top: .75rem;
					width: auto;
					width: 10.5rem;
				}
			}

			@include mq($screen-lg) {
				text-align: center;
			}
		}
	}

	&__heading {
		@extend .student__section-heading, .h2;
		color: $gray-light;
		text-transform: none;
		font-weight: 300;
		text-align: center;

		@include mq($screen-md) {
			margin-top: 1rem;
		}

		&-name,
		&-date,
		&-end-date {
			font-weight: 500;
		}
	}

	&__subheading {
		font-size: $font-size-lg;
		font-weight: normal;
		line-height: 2rem;
		vertical-align: middle;
		margin-bottom: 0;
	}

	&__table {
		@extend .table-responsive;
		margin-top: 0;
		width: 100%;

		@include mq($screen-md) {
			display: table;
		}
	}

	th {
		font-size: $font-size-sm;
		padding-right: 1.25rem;

		@include mq($screen-lg) {
			white-space: nowrap;
		}

		&.mins {
			text-align: left;
			padding-left: 2.5rem;
		}
	}

	tr {
		&.connect-extra-heading {
			background-color: $cnc-blue-lightest;
			color: $gray;
			text-transform: uppercase;
			font-weight: 700;
			padding: 15px 0;

			td {
				padding: 1rem .3rem;
			}

		}
		&.connect-type {
			height: 75px;
			background-color: $gray-lighter;
			border-top: 4px solid $gray;
			font-weight: 700;
			color: $gray-light;
			text-transform: uppercase;

		}

		&.connect-type-spacer {
			height: 25px;
		}
	}

	td.student-data__cell {
		padding: .65rem .45rem;

		@include mq($screen-md) {
			padding: .65rem .75rem;
		}

		.input-group {
			justify-content: center;

			.form-control {
				min-width: 4rem;
				max-width: 4rem;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}

		&.mins {
			.form-control-label {
				display: inline-block;
				font-size: $font-size-xs;
				text-transform: uppercase;
				line-height: 2.2rem;
				padding-left: .25rem;
			}
			
			.input-group {
				&:after {
					right: 0;
				}
			}
		}

		&-fieldname {
			text-align: left;
			padding-right: 0;
			font-size: $font-size-sm;
			min-width: 14rem;

			@include mq($screen-md) {
				padding-left: 1.25rem;
				min-width: 8rem;
				max-width: 10rem;
			}
		}

		&-actions {
			min-width: 3rem;
			padding-top: .5rem;
			vertical-align: middle;
		}
	}

	.input-group,
	.form-has-icon {
		&:after {
			display: inline-block;
			font-family: 'FontAwesome';
			content: "";
			width: 20px;
			padding: .4rem .5rem 0;
			position: absolute;
			right: 15px;
		}

		&.has-success {
			&:after {
				content: "\f058";
				color: $brand-success;
			}
		}

		&.has-danger {
			&:after {
				content: "\f06a";
				color: $brand-danger;
			}
		}
	}

	.form-has-icon {
		&:after {
			padding: 0;
		}
	}

}

/*  Course Performance Stuff
	------------------------------------ */

.student-data {
	.passed {
		color: $green;
	}

	.failed {
		color: $red;
	}

	&__new-course {
		&.is-opened {
			height: 100%;
		}
		
		.selectExistingCourse {
			display: none;
			
			.form-control {
				border-radius: 5px;
				max-height: 200px;
				overflow-y: scroll;
				
				.radio {
					border-bottom: 1px solid rgba(0, 0, 0, 0.15);
					padding: 8px 0 2px;
					
					&:last-of-type {
						border-bottom: 0;
					}
				}
				
				input {
					width: auto;
					display: inline-block;
					vertical-align: top;
					margin-right: 5px;
				}
				
				label {
					width: 100%;
					display: block;
					line-height: 1rem;
					
					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}

	&__existing {
		@extend .row;
		margin-bottom: 1rem;

		&-col {
			@extend .col-12;

			// &.col-1 {
			// 	@extend .col-sm-6;
			// }
			
			.selectCourseType {
				width: 50%;
			}
		}
	}

	&__add-course {
		@extend .row;
		padding: .75rem;

		&-heading {
			@extend .col-12;
			margin: 1rem 0;
			text-transform: uppercase;
			border-bottom: 2px solid $border-color;
		}

		&-col {
			@extend .col-12;

			&.half {
				@extend .col-sm-6;
			}
		}

	}

	&-course {
		&__table {
			@extend .table, .table-responsive;

			@include mq($screen-md) {
				display: table;
			}

			th {
				border-top: 0;
				color: $gray;
				text-transform: uppercase;
				min-width: 8rem;
				vertical-align: middle;

				&:first-child {
					padding-right: .5rem;
				}
			}

			td {
				&.student-data-course__cell {
					&-fieldname {

						> a {
							&:after {
								font-family: 'FontAwesome';
								content: "";
							}

							@include hover-states {
								text-decoration: none;

								&:after {
									content: "\f040";
								}
							}
						}
					}

					&-grade {
						width: 5rem;
						padding-left: 0.6rem;

						input {
							width: 100%;
							max-width: 100%;
						}
						
						.input-group {
							&.has-success,
							&.has-danger {
								&::after {
									right: 0;
								}
							}
						}
						
						.percent {
							padding: 0.5rem 1rem 0.5rem 0.5rem;
						}
					}

					&-change {
						padding-left: 1rem;
						width: 5rem;
						max-width: 5rem;
					}
				}
			}

			th,
			td {
				text-align: center;

				&.student-data-course__cell {
					&-fieldname {
						min-width: 14rem;
						width: 14rem;
						text-align: left;
					}
					&-reorder {
						width: 3rem;
						max-width: 3rem;
					}
				}
			}
		}
	}
}

//end enrollment form
.end-enrollment-form {
	margin-top: 30px;
}

/*  Connects
	------------------------------------ */

.student-data {

	&__new-connect {

		.card-block {
			@include mq($screen-lg) {
				padding-top: 2rem;
			}
		}
	}

	&__add-connect {
		@extend .row, .no-gutters, .align-content-start;
		justify-content: space-between;

		&-col {
			@extend .col-12;

			label {
				text-transform: uppercase;
				letter-spacing: 0px;
				font-size: $font-size-sm;
				padding-bottom: .5rem;
				padding-top: .5rem;
			}
		}

		.col-1,
		.col-2,
		.col-3,
		.col-4 {
			padding: 0;
		}

		.col-1 {
			@extend .col-sm-12, .col-lg-5;
			position: relative;
			margin-bottom: .5rem;


			@include mq($screen-md) {
				margin-bottom: 0;
				padding-right: 0;
			}

			.student-data__new-field {
				position: absolute;
				top: .35rem;
				right: 0;
			}
		}

		.col-2,
		.col-3 {
			@extend .col-sm-4, .col-lg-2;
		}

		.col-4 {
			@extend .col-sm-3, .col-lg-2, .align-self-center;

			@include mq($screen-sm) {
				text-align: right;
				padding-top: .25rem;
			}

			@include mq($screen-md) {
				text-align: left;
			}
		}
	}
}

.toggle-hide-input {
	visibility: hidden;
	display: none;
}

.check-custom {
	input[type=checkbox]{
		display: none;

		&:checked {
			+ label {
				&::before{
					content: "\2713";
					font-size: 22px;
					color: $green;
					text-align: center;
					line-height: 22px;
				}
			}
		}
	}


	label {
		margin-top: .25rem;
		margin-bottom: .25rem;
		display: inline-block;
		cursor: pointer;
		position: relative;
		font-size: 13px;

		&::before{
			content: "";
			display: inline-block;
			
			width: 22px;
			height: 22px;
			
			border: 1px solid $black;
		}

		&.disabled {
			cursor: not-allowed;

			&::before {
				border: 1px solid $gray-light;
				opacity: .5;

			}
		}
	}
}

.check-key {
	display: flex;
	padding: .75rem 1.25rem;
	color: $gray-light;
	font-size: .75rem;

	.key-header {
		font-weight: 700;
		color: $gray;
		text-transform: uppercase;
		font-size: 1rem;
	}
}