[data-js='tips'] {
  color: $cnc-tan;

  .button-primary & {
    color: $white;
	}
}

.tips {
  .tooltip-inner {
    padding-bottom: .75rem;
	}
}

.tooltip {
  font-size: $font-size-sm;
}

.tooltip-inner {
  text-align: left;

  h5,
  h6 {
    color: $white;
    font-size: $font-size-sm;
    font-weight: 800;
    margin-top: .5rem;
	}

  p {
    &:first-child {
      padding-top: .5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
