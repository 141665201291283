.cnc-tabs {
  @extend .nav-tabs;
  display: block;
  position: relative;

  @include mq($screen-md) {
    display: flex;
  }

  .nav-link {
    border-radius: 0;
    background-color: $cnc-blue-lightest;
    color: $gray;
    font-size: $font-size-base;
    border: 1px solid $cnc-blue-lightest;
    border-right: 1px solid $cnc-blue-lightest;
    border: 1px solid $white;
    border-bottom-color: $border-color;
    margin-left: .015rem;

    &:hover,
    &.active {
      background-color: $cnc-blue;
      color: $white;
      border: 1px solid $white;
      border-bottom-color: $border-color;
    }
  }
}

.cnc-tab-content {
  @extend .tab-content;
  position: relative;
  padding-top: 1.5rem;

  @include mq($screen-md) {
    padding: 1.5rem 2rem 0;
    margin-right: 1.5rem;
  }
}

.cnc-tabs__nav {
  position: absolute;
  display: flex;
  align-items: center;
  align-content: center;
  width: 4rem;
  height: 100%;
  top: 0;

  &:hover,
  &:focus,
  &:active {
    button {
      cursor: pointer;
      //opacity: 1;
    }
  }

  &.prev {
    left: 0;
  }

  &.next {
    right: 0;
  }
}

.cnc-nav__prev,
.cnc-nav__next {
  display: block;
  width: 4rem;
  height: 5.5rem;
  padding: .45rem .5rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#ccc' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  background-color: $white;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: .65rem 50%;
  border: none;
  z-index: 100;
  margin-top: 45rem;
  transition: opacity 0.3s ease-in-out;

  @include mq($screen-sm) {
    margin-top: 30rem;
  }

  @include mq($screen-md) {
    margin-top: 15rem;
  }

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
  }
}

.cnc-nav__next {
  transform: rotate(180deg);
}