.program-detail-print {
  color: #000;

  b {
    font-weight: 500;
  }

  .nav-main__header,
  .freshwidget-container,
  .freshwidget-button,
  #utilities-container,
  .dashboard__header,
  .fa-question-circle,
  .footer__main {
    display: none !important;
  }

  .student-detail__header h3,
  .student-detail__header dl,
  .student-detail__list-heading,
  h1, h2, h3, h4, h5, h6 {
    color: #000 !important;
  }

  .page-header__container,
  .program {
    max-width: 980px;
    padding-bottom: 0;
  }

  .student-detail__header {
    padding: 0;
    text-align: left !important;

    dl {
      dt {
        font-weight: 600;
      }
      dd {
        font-weight: 400;
      }
    }
  }

  .student-detail {
    background: #fff;
    padding: 0;
    border: none;
  }

  .card-block {
    padding: 0;
  }

  .student-detail__info-body {
    padding: 0;
    max-width: 66.6% !important;

    @media(max-width: 980px) {
      max-width: 100% !important;
    }
  }

  .student-detail__info-row,
  .student-detail__info-progress {
    margin-left: 0;
    margin-right: 0;
    color: #000 !important;
    display: block;

    .student-detail__sublist {
      margin: 5px 0 0;
      font-size: .94rem;

      b {
        font-weight: 600;
      }
    }
  }

  .calendar__legends-heading {
    text-align: left;
    padding: 0;
    color: #000;
  }

  .program-report__calendar {
    text-align: left;

    table {
      width: 30%;
      min-height: 260px;

      @media only screen and (max-width: 980px) {
        width: auto;
      }

      @media only screen {
        .month {
          min-width: 285px;
        }
      }
    }
  }
  
  .program-report__notes {
    padding: 0;
  }

  .report-section {
    padding-top: 10px;
  }
}
