%button-shadow {
	box-shadow: 0 2px 1px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 2px 0 rgba(0,0,0,.12);
}

%button,
.button, {
	@extend .btn;
	font-size: 0.85rem;
	text-transform: uppercase;
	font-weight: 500;
	border-radius: $border-radius;

	&:active,
	&:hover,
	&:focus {
		cursor: pointer;
		text-decoration: none;
	}

	&[disabled],
	&.disabled {
		box-shadow: none;
	}

	i {
		padding-right: .3rem;
	}

	&-link {
		@extend .btn-link;
		font-weight: bold;
	}

	&-default {
		@extend .btn-outline-primary;
		background-color: $white;
		border-color: $border-color;
		color: $gray;
		letter-spacing: normal;
		font-weight: 600;

		&:active,
		&:hover,
		&:focus {
			background-color: $cnc-blue !important;
			border-color: $cnc-blue-lighter !important;
			color: $white !important;
		}
	}

	&-primary {
		@extend .btn-primary;
		letter-spacing: 1px;
		font-weight: 300;
		@extend %button-shadow;
	}

	&-secondary {
		@extend .btn-secondary;
		@extend %button-shadow;
	}

	&-accent {
		@extend .button;
			background-color: $btn-accent-bg;
	    color: $btn-accent-color;
	    @extend %button-shadow;
	}
}

.button,
.btn {
	&--sm,
	&-sm {
		@extend .btn-sm;
		font-size: $font-size-xxs;
		font-weight: 600;
	}

	&--lg,
	&-lg {
		@extend .btn-lg;
		font-size: $font-size-base;
	}

	&--xlg,
	&-xlg {
		padding: $btn-padding-y-xlg $btn-padding-x-xlg;
		font-size: $font-size-xlg;
	}

	&--wide,
	&-wide {
		padding: $btn-padding-y 2rem;
	}

	&--full-width {
		width: 100%;
	}

	&--success {
		@extend .btn-success;
	}

	&--warn {
		@extend .btn-warning;
	}

	&--danger {
		@extend .btn-danger;
	}

	&-icon-only {
		i {
			padding-right: 0;
		}
	}

	&--no-radius {
		border-radius: 0;
	}

	&--shadowless {
		box-shadow: none;
	}
}

.button-outline {
	&-default {
		@extend .btn-outline-primary;
		border-color: $border-color;
		color: $gray;
	}
}