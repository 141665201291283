.is-stuck {
	position: fixed;
	z-index: 999;
	width: 100%;
	top: 0;
	box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.45);

	+ aside {
		padding-top: 80px;
	}

	+ div, + section {
		padding-top: 125px;
	}
}