.errors {
	margin-bottom: 30px;

	.errorlist {
		list-style: none;
		margin: 0;
		padding: .5rem 0 0;

		li {
			&:before {
				font-family: 'FontAwesome';
				content: "\f06a";
				padding-right: .5rem;
				padding-left: .5rem;
			}

			padding-bottom: .5rem;
		}
	}
}