//For SortableJS
.sortable-handle {
	display: inline-block;
	color: $cnc-blue;

	@include hover-states {
		cursor: move;
	}
}

.sortable-chosen,
.sortable-chosen .card {
  background-color: lighten($cnc-yellow, 35%);
}